html {
    background: var(--color-white);
}

body {
    margin: 0;
}

#ui {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 auto;
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(
      safe-area-inset-bottom
    ) env(safe-area-inset-left);
}

//button,
.button {
    width: 280px;
    height: 56px;
    border-radius: 8px;
    color: var(--color-blackish);
    cursor: pointer;
}

button.small,
.button.small {
    width: 103px;
    height: 40px;
    border-radius: 4px;
}

button.medium,
.button.medium {
    width: 224px;
    height: 72px;
}

button.large,
.button.large {
    width: 304px;
    height: 112px;
    border-radius: 12px;
}

button.primary,
.button.primary {
    color: var(--color-white);
    background: var(--color-grape-sanchez);
}

button.secondary,
.button.secondary {
    background: var(--color-holy-greyl);
}

button.tertiary,
.button.tertiary {
    border: 1px solid var(--color-steel-magnolias);
    box-sizing: border-box;
    background: var(--color-white);
}

button.primary.pressed,
button.primary:hover,
.button.primary.pressed,
.button.primary:hover {
    background: var(--color-berry);
}

button.secondary.pressed,
button.secondary:hover,
.button.secondary.pressed,
.button.secondary:hover {
    background: var(--color-grey-dawn);
}

button.tertiary.pressed,
button.tertiary:hover,
.button.tertiary.pressed,
.button.tertiary:hover {
    background: var(--color-holy-greyl);
    border: 1px solid var(--color-charlie-st-cloud);
}

button.primary.inactive,
.button.primary.inactive {
    background: var(--color-charlie-st-cloud);
}

button.secondary.inactive,
.button.secondary.inactive {
    background: var(--color-holy-greyl);
}

button.tertiary.inactive,
.button.tertiary.inactive {
    background: var(--color-holy-greyl);
}

a {
    cursor: pointer;
}

// SideNavigation styles
#body {
    #ui {
        nav[aria-label='Primary']  {
            li :hover {
                background-color: rgba(240, 233, 250, 0.4);
                color: var(--color-grape-sanchez);
                a {
                    color: var(--color-grape-sanchez);
                }

                span {
                    background: none;
                }

                svg path {
                    fill: var(--color-grape-sanchez);
                }
            }

            li a[aria-current='page'] {
                color: var(--color-grape-sanchez);
                a {
                    color: var(--color-grape-sanchez);
                }

                svg path {
                    fill: var(--color-grape-sanchez);
                }
            }

        }

        nav[aria-label='Secondary'] {
            ul > li > ul > li :hover {
                background-color: rgba(240, 233, 250, 0.4);
                color: var(--color-grape-sanchez);
                a {
                    color: var(--color-grape-sanchez);
                }

                span, a, strong, p {
                    background: none;
                }

                svg path {
                    fill: var(--color-grape-sanchez);
                }
            }

            li a[aria-current='page'] {
                background-color: rgba(240, 233, 250, 0.4);
                color: var(--color-grape-sanchez);
                a {
                    color: var(--color-grape-sanchez);
                }

                svg path {
                    fill: var(--color-grape-sanchez);
                }
            }

        }
    }

    .ReactModalPortal nav[aria-label='Primary'] {
        li {
            a[aria-current='page'] {
                color: var(--color-grape-sanchez);
                a {
                    color: var(--color-grape-sanchez);
                }

                svg path {
                    fill: var(--color-grape-sanchez);
                }
            }

            button[aria-expanded='false'] {
                color: initial;
                background-color: white;
                a {
                    color: initial;
                }

                span, p {
                    color: initial;
                }

                svg path {
                    fill: initial;
                }
            }

            button[aria-expanded='true'], button[aria-current='page'] {
                color: var(--color-grape-sanchez);
                background-color: white;
                a {
                    color: var(--color-grape-sanchez);
                }

                span, p {
                    color: var(--color-grape-sanchez);
                }

                svg path {
                    fill: var(--color-grape-sanchez);
                }
            }

            button ~ ul a[aria-current='page'] {
                background-color: rgba(240, 233, 250, 0.4);
            }
        }
    }
}

.recharts-legend-item-text {
    text-transform: capitalize;
    margin-right: 12px !important;
    color: black !important;
}


.recharts-tooltip-wrapper {
    text-transform: capitalize;
}

// Tis is neccesary for tip tap to not render a blue outline inside the editor when it's focused
.ProseMirror-focused {
    outline: none;
}