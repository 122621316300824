.react-time-picker {
  display: inline-flex;
  position: relative;
}
.react-time-picker,
.react-time-picker *,
.react-time-picker *:before,
.react-time-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-time-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}
.react-time-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
}
.react-time-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 6 + 0.217em * 2);
  flex-grow: 1;
  box-sizing: content-box;
}
.react-time-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}
.react-time-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;

  outline: none;
  color: #353843;
}
.react-time-picker__inputGroup__input::-webkit-outer-spin-button,
.react-time-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-time-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}
.react-time-picker__inputGroup__amPm {
  font: inherit;
  -moz-appearance: menulist;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75em;
  line-height: 150%;
  /* or 18px */
  align-items: center;
  text-align: center;

  border: 1px solid #e1e4ec;
  border-radius: 4px;
  padding: 1.5px;
}
.react-time-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}
.react-time-picker__button:enabled {
  cursor: pointer;
}
.react-time-picker__button:enabled:hover .react-time-picker__button__icon,
.react-time-picker__button:enabled:focus .react-time-picker__button__icon {
  stroke: #0078d7;
}
.react-time-picker__button:disabled .react-time-picker__button__icon {
  stroke: #6d6d6d;
}
.react-time-picker__button svg {
  display: inherit;
}
.react-time-picker__clock {
  width: 200px;
  height: 200px;
  max-width: 100vw;
  padding: 25px;
  background-color: white;
  border: thin solid #a0a096;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}
.react-time-picker__clock--closed {
  display: none;
}
.timePickerContainer {
  float: right;
  width: 344px;
  margin-bottom: 2em;
  display: flex;
}
.startTimePickerContainer,
.endTimePickerContainer {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  align-items: flex-start;
  margin-left: 10px;
}
.timePickerLabel {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75em;
  line-height: 150%;
  color: #353843;

  margin-bottom: 12px;
}
.react-time-picker__inputGroup__hour {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75em;
  line-height: 150%;
  /* or 18px */
  align-items: center;
  text-align: center;

  border: 1px solid #e1e4ec;
  border-radius: 4px;
  margin-right: 6px;
  padding: 1.5px 7px;
}
.react-time-picker__inputGroup__minute {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75em;
  line-height: 150%;
  /* or 18px */
  align-items: center;
  text-align: center;

  border: 1px solid #e1e4ec;
  border-radius: 4px;
  margin-left: 6px;
  margin-right: 6px;
  padding: 1.5px 7px;
}
.react-time-picker__inputGroup__leadingZero {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75em;
  line-height: 150%;
  /* or 18px */
  margin-left: 13px;
}
.react-time-picker__inputGroup__input--hasLeadingZero {
  margin-left: -15px;
  padding-left: 14px;
}

.rdrInputRanges {
  display: none !important;
}

.datepicker .calendar .visible {
  display: block !important;
}

.datepicker .calendar .invisible {
  display: none !important;
}
