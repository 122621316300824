:root {
  /* general */
  --color-white: #ffffff;
  --color-black: #000000;
  --color-holy-greyl: #f5f6fa;
  --color-a-blue-hope: #2172e8;
  --color-a-crushed-hope: #1750a2;

  /* grey */
  --color-blm: #171636;
  --color-blackish: #353843;
  --color-steel-magnolias: #737b8b;
  --color-angrey-birds: #aaaebc;
  --color-charlie-st-cloud: #cdcfd8;
  --color-grey-dawn: #e1e4ec;
  --color-eva: #c5d0de;

  /* light */
  --color-oceans-11: #2172e8;
  --color-grace: #d7dffd;
  --color-greys-anatomy: #e1e6f9;
  --color-grey-morning: #ebedf7;
  --color-light-smoke: #f2f4f9;
  --color-mist: #f8faff;
  --color-vanilla-sky: #fcfdfe;

  /* purple */
  --color-berry: #5714c1;
  --color-grape-sanchez: #6b20ce;
  --color-orchid: #7942d1;
  --color-purple-hearts: #9876ce;
  --color-purple-butterfly: #b092e8;
  --color-muave: #c7adf2;
  --color-periwinkle: #ddcaf9;

  /* blue */
  --color-purple-rain: #0a0b44;
  --color-midnight: #140a56;
  --color-deep-impact: #1f1f75;
  --color-lavender: #4f5ec6;
  --color-lilac: #626cd3;
  --color-silver: #7978ed;
  --color-violet: #afaff2;

  /* red */
  --color-jaws: #e91736;
  --color-chili-pepper: #ed3b37;
  --color-pink-panther: #f4675d;
  --color-ballet-slipper: #f4a198;
  --color-crepe: #fcbdb4;
  --color-gum: #f7c9c4;
  --color-dusty-rose: #f4dcdc;

  /* orange */
  --color-amber: #a06338;
  --color-camel: #b77548;
  --color-bronze: #d18959;
  --color-caramel: #f29b00;
  --color-the-simpsons: #ffaa00;
  --color-taxi: #ffbc00;
  --color-yellow-submarine: #ffd400;

  /* green */
  --color-green-mile: #218921;
  --color-forest: #0e9655;
  --color-big-green: #07dd4e;
  --color-sea-green: #15aaa3;
  --color-aqua: #1ec6ba;
  --color-mint: #7aedcc;
  --color-sea-foam: #9ff4d7;
}
