html {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    color: var(--color-blackish);
}
#content {
    h1,
    .h1 {
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
    }

    h2,
    .h2 {
        font-weight: 600;
        font-size: 28px;
        line-height: 40px;
    }

    h3,
    .h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
    }

    h4,
    .h4 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
    }

    h4.sub,
    .h4.sub {
        color: var(--color-steel-magnolias);
    }

    h5,
    .h5 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    h5.sub,
    .h5.sub {
        color: var(--color-steel-magnolias);
    }

    h6,
    .h6 {
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
    }

    h6.sub,
    .h6.sub {
        color: var(--color-steel-magnolias);
    }

    .text.huge {
        font-size: 24px;
        line-height: 36px;
    }

    .text.large {
        font-size: 20px;
        line-height: 24px;
    }

    .text.medium {
        font-size: 16px;
        line-height: 24px;
    }

    .text {
        font-size: 12px;
        line-height: 150%;
    }

    .normal {
        font-weight: 400;
    }

    .bold {
        font-weight: 600;
    }

    .caption {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .caption.sub {
        font-weight: normal;
    }

    a {
        font-size: 16px;
        line-height: 19px;
        color: var(--color-a-blue-hope);
        text-decoration: none;
    }

    a:hover {
        color: var(--color-a-crushed-hope);
        text-decoration: none;
    }
}
